import * as Axios from '@/axios/index'
import {config} from 'vue-touch';

export const globalStr = '/apis/basicdata';//基础接口
export const placeStr = '/apis/place';//5G安全管家接口-场所
export const deviceStr = '/apis/device';//5G安全管家接口-设备服务
export const sceneStr = '/apis/scene';//5G安全管家接口-场景配置
export const mqttStr = '/apis/mqtt';//5G安全管家接口-MQTT配置

export let apiUrl = {
    // 登录
    login: function (data) {
        return Axios.default.get(`${globalStr}/login?${data}`)
    },
    // 登出
    logout: function (data) {
        return Axios.default.get(`${globalStr}/logout?${data}`)
    },
    // 修改当前登录用户密码
    userUpdatePwd: function (data) {
        return Axios.default.put(`${globalStr}/user/updatePwd?${data}`)
    },
    // 获取当前登录用户信息
    userGetDetail: function () {
        return Axios.default.get(`${globalStr}/user/getDetail`)
    },
    // 静态模板文件下载
    templateFileDownload: function (data) {
        return Axios.default.getFile(`${globalStr}/templateFile/download/${data}`)
    },
    /**
     * 数据字典
     */
    // 获取数据字典
    getDicts: function (data) {
        return Axios.default.post(`${globalStr}/dict/listByDictDomains`, data)
    },

    /**
     * 后台管理-平台信息
     */
    // 查询当前登录应用的详细数据
    getAppVo: function (data) {
        return Axios.default.get(`${globalStr}/app/getAppVo?${data}`)
    },
    // 根据应用Id更新应用信息
    updateApp: function (data) {
        return Axios.default.postJson(`${globalStr}/app`, data)
    },
    // 查询全部应用
    getAllApp: function () {
        return Axios.default.get(`${globalStr}/app/getAll`)
    },

    /**
     * 后台管理-菜单管理
     */
    // 根据应用Code获取应用的页面菜单树
    getPageMenuTreeByAppCode: function (data) {
        return Axios.default.get(`${globalStr}/menu/getPageMenuTreeByAppCode?${data}`)
    },
    // 新增
    addMenuInsert: function (data) {
        return Axios.default.post(`${globalStr}/menu/insert`, data)
    },
    // 修改
    updateMenu: function (data) {
        return Axios.default.put(`${globalStr}/menu/update`, data)
    },
    // 批量排序
    batchUpdateIdx: function (data) {
        return Axios.default.put(`${globalStr}/menu/batchUpdateIdx`, data)
    },
    // 删除
    deleteMenu: function (data) {
        return Axios.default.delete(`${globalStr}/menu/batchDelete`, data)
    },
    // 根据当前登录用户获取应用的页面菜单树
    getPageMenuTreeCurrUser: function () {
        return Axios.default.get(`${globalStr}/menu/getPageMenuTree`)
    },

    /**
     * 后台管理-用户权限
     */
    // 分页查询用户列表
    userListPage: function (data) {
        return Axios.default.post(`${globalStr}/user/listPage`, data)
    },
    // 用户列表
    userList: function (data) {
        return Axios.default.post(`${globalStr}/user/list`, data)
    },
    // 新增用户
    addUserInsert: function (data) {
        return Axios.default.post(`${globalStr}/user/insert`, data)
    },
    // 修改用户
    updateUser: function (data) {
        return Axios.default.put(`${globalStr}/user/update`, data)
    },
    // 批量删除用户
    deleteUser: function (data) {
        return Axios.default.delete(`${globalStr}/user/batchDelete`, data)
    },
    // 查询在线用户列表
    userListOnline: function (data) {
        return Axios.default.post(`${globalStr}/user/listOnline`, data)
    },
    // 查询本部门用户列表
    listUserVoOwnDepartment: function (data) {
        return Axios.default.post(`${globalStr}/user/listUserVoOwnDepartment`, data)
    },
    // 查询用户列表(携带是否含有指纹人脸等下发信息标识字段)
    listUserVoWithSendInfo: function (data) {
        return Axios.default.post(`${globalStr}/user/listUserVoWithSendInfo`, data)
    },
    // 同步添加sip服务器分机号列表
    userSyncUser: function () {
        return Axios.default.get(`${globalStr}/user/syncUser`)
    },
    // 查询未被绑定的成员列表（包含成员属性，PC-调度台，APP-手持终端）
    userListUnbind: function () {
        return Axios.default.get(`${globalStr}/cy/user/listUnbind`)
    },
    // 批量修改用户部门/角色
    userBatchUpdate: function (data) {
        return Axios.default.put(`${globalStr}/user/batchUpdate`, data)
    },
    // 重置用户密码
    userResetPwd: function (data) {
        return Axios.default.put(`${globalStr}/user/resetPwd/${data}`)
    },
    // 根据sip账号获取用户详情
    getUserVoBySipAccount: function (data) {
        return Axios.default.get(`${globalStr}/user/getUserVoBySipAccount?${data}`)
    },
    // 查询全部用户
    userGetAll: function () {
        return Axios.default.get(`${globalStr}/user/getAll`)
    },

    /**
     * 后台管理-角色管理
     */
    // 分页查询角色列表
    roleListPage: function (data) {
        return Axios.default.post(`${globalStr}/role/listPage`, data)
    },
    // 角色列表
    roleList: function (data) {
        return Axios.default.post(`${globalStr}/role/list`, data)
    },
    // 新增角色
    addRoleInsert: function (data) {
        return Axios.default.post(`${globalStr}/role/insert`, data)
    },
    // 修改角色
    updateRole: function (data) {
        return Axios.default.put(`${globalStr}/role/update`, data)
    },
    // 删除角色
    deleteRole: function (data) {
        return Axios.default.delete(`${globalStr}/role/batchDelete`, data)
    },


    /**
     * 后台管理-部门管理
     */
    // 分页查询部门列表
    departmentListPage: function (data) {
        return Axios.default.post(`${globalStr}/department/listPage`, data)
    },
    //获取当前登录用户的部门树
    getDepartmentTree: function (data) {
        return Axios.default.get(`${globalStr}/department/getDepartmentTree?${data}`)
    },
    //获取部门列表
    departmentList: function (data) {
        return Axios.default.post(`${globalStr}/department/list`, data)
    },
    // 新增部门
    addDepartmentInsert: function (data) {
        return Axios.default.post(`${globalStr}/department/insert`, data)
    },
    // 修改部门
    updateDepartment: function (data) {
        return Axios.default.put(`${globalStr}/department/update`, data)
    },
    // 删除部门
    deleteDepartment: function (data) {
        return Axios.default.delete(`${globalStr}/department/batchDelete`, data)
    },
    // 更新区域权限
    updateAreaAuthorize: function (data) {
        return Axios.default.post(`${globalStr}/areaAuthorize/update`, data)
    },

    /**
     * 后台管理-人员管理
     */
    // 分页查询人员列表
    personListPage: function (data) {
        return Axios.default.post(`${globalStr}/person/listPage`, data)
    },
    // 新增人员
    addPersonInsert: function (data) {
        return Axios.default.postJson(`${globalStr}/person/insert`, data)
    },
    // 修改人员
    updatePerson: function (data) {
        return Axios.default.putForm(`${globalStr}/person/update`, data)
    },
    // 查询人员列表
    personList: function (data) {
        return Axios.default.post(`${globalStr}/person/list`, data)
    },
    // 删除人员
    deletePerson: function (data) {
        return Axios.default.delete(`${globalStr}/person/batchDelete`, data)
    },
    //获取职务列表
    postList: function () {
        return Axios.default.get(`${globalStr}/post/list`)
    },
    // 根据人员id集合清空人员指纹
    deleteByPersonIds: function (data) {
        return Axios.default.delete(`${globalStr}/fingerprint/deleteByPersonIds`, data)
    },
    // 批量添加人员
    personUpload: function (data) {
        return Axios.default.postJson(`${globalStr}/person/upload`, data)
    },
    /**
     * 后台管理-系统参数
     */
    // 根据系统参数域查询参数列表
    sysParamList: function (data) {
        return Axios.default.get(`${globalStr}/sysParam/list?${data}`)
    },
    // 根据系统参数域查询参数json数据
    sysParamGetMap: function (data) {
        return Axios.default.get(`${globalStr}/sysParam/getMap?${data}`)
    },
    // 批量更新系统参数
    BatchUpdateParamValue: function (data) {
        return Axios.default.put(`${globalStr}/sysParam/batchUpdateParamValue`, data)
    },
    // 查询全部系统参数数据
    sysParamGetAll: function () {
        return Axios.default.get(`${globalStr}/sysParam/getAll`)
    },
    /**
     * 平台授权
     */
    // 获取授权许可信息
    authLicenseInfo: function () {
        return Axios.default.get(`${globalStr}/auth/license/info`)
    },
    // 下载授权机器码
    authLicenseDownload: function () {
        return Axios.default.downPost(`${globalStr}/auth/machineCode/download`)
    },
    // 上传授权许可文件
    authLicenseUpload: function (data) {
        return Axios.default.postJson(`${globalStr}/auth/license/upload`, data)
    },

    /**
     * 后台管理-工单管理
     */
    // 分页查询工单列表
    ticketListPage: function (data) {
        return Axios.default.post(`${globalStr}/ticket/page`, data)
    },
    // 添加工单
    addTicket: function (data) {
        return Axios.default.post(`${globalStr}/ticket/add`, data)
    },
    // 修改工单
    updateTicket: function (data) {
        return Axios.default.put(`${globalStr}/ticket/update`, data)
    },
    // 工单详情
    ticketDetail: function (ticketId) {
        return Axios.default.get(`${globalStr}/ticket/get/${ticketId}`)
    },
    // 获取处理人
    ticketGetPerson: function (homeId) {
        return Axios.default.get(`${globalStr}/ticket/getPerson/${homeId}`)
    },
    // 获取设备类型
    ticketGetDeviceType: function () {
        return Axios.default.get(`${globalStr}/ticket/getDeviceType`)
    },
    // 工单类型
    getTicketType: function (type) {
        return Axios.default.get(`${globalStr}/ticket/getTicketType/${type}`)
    },
    // 处理工单
    ticketHandle: function (data, timeNum) {
        return Axios.default.postJson(`${globalStr}/ticket/handle`, data, timeNum)
    },
    // 审核工单
    ticketAudit: function (data) {
        return Axios.default.post(`${globalStr}/ticket/audit`, data)
    },
    // 撤销审批
    ticketRevoke: function (ticketId) {
        return Axios.default.get(`${globalStr}/ticket/revoke/${ticketId}`)
    },
    // 取消工单
    ticketCancel: function (data) {
        return Axios.default.post(`${globalStr}/ticket/cancel`, data)
    },
    // 重新派单
    ticketAgain: function (data) {
        return Axios.default.post(`${globalStr}/ticket/dispatchAgain`, data)
    },
    // 待审核修改工单
    ticketAuditModify: function (data) {
        return Axios.default.putForm(`${globalStr}/ticket/auditModify`, data)
    },
    // 查看工单
    ticketView: function (data) {
        return Axios.default.get(`${globalStr}/ticket/view/${data}`)
    },

    /**
     * 安全管家-区域管理
     */
    // 查询区域树形列表
    getAreaList: function () {
        return Axios.default.get(`${placeStr}/area/getArea`)
    },
    // 新增区域
    createArea: function (data) {
        return Axios.default.post(`${placeStr}/area/createArea`, data)
    },
    // 修改区域
    modifyArea: function (data) {
        return Axios.default.post(`${placeStr}/area/modifyArea`, data)
    },
    // 删除区域
    deleteArea: function (data) {
        return Axios.default.post(`${placeStr}/area/delete?${data}`)
    },
    /**
     * 安全管家-场所管理
     */
    // 分页获取场所列表接口
    getPageHome: function (data) {
        return Axios.default.post(`${placeStr}/home/getPageHome`, data)
    },
    // 分页获取场所列表接口
    getHome: function (data) {
        return Axios.default.get(`${placeStr}/home/getHome?${data}`)
    },
    // 创建场所接口
    createHome: function (data, timeNum) {
        return Axios.default.postJson(`${placeStr}/home/createHome`, data, timeNum)
    },
    // 修改场所接口
    modifyHome: function (data, timeNum) {
        return Axios.default.postJson(`${placeStr}/home/modifyHome`, data, timeNum)
    },
    // 删除场所接口
    deleteHome: function (data) {
        return Axios.default.delete(`${placeStr}/home/delete`, data)
    },
    // 修改短信通知开关
    changeSmsFlag: function (data) {
        return Axios.default.post(`${placeStr}/home/changeSmsFlag`, data)
    },
    /**
     * 安全管家-电气设备管理
     */
    // 分页获取电气设备列表接口
    devicePageList: function (data) {
        return Axios.default.post(`${deviceStr}/device/list`, data)
    },
    // 获取电气设备类别列表接口
    deviceCategoryList: function (data) {
        return Axios.default.get(`${deviceStr}/device/category/list/${data}`);
    },
    // 获取电气设备网关列表接口
    deviceGatewayList: function (data) {
        return Axios.default.get(`${deviceStr}/electric/getGatewayList/${data}`);
    },
    // 新增电气设备列表接口
    addDevice: function (data) {
        return Axios.default.post(`${deviceStr}/device/add`, data)
    },
    // 快速同步微羽平台设备
    addQuickDevice: function (data) {
        return Axios.default.get(`${deviceStr}/device/sync?${data}`)
    },
    // 修改电气设备接口
    updateDevice: function (data) {
        return Axios.default.put(`${deviceStr}/device/update`, data)
    },
    // 删除电气设备接口
    deleteDevice: function (data) {
        return Axios.default.delete(`${deviceStr}/device/delete?${data}`)
    },
    // 批量控制开关电气设备接口
    batchControlDevice: function (data) {
        console.log(data);
        return Axios.default.post(`${deviceStr}/device/batchControl`, data)
    },
    // 设备操作日志
    deviceLogs: function (data) {
        return Axios.default.post(`${deviceStr}/device/logs`, data)
    },
    // 设备安全参数
    deviceSafeParams: function (data) {
        return Axios.default.get(`${deviceStr}/electric/getSafeParams/${data}`)
    },
    //海康空开发现
    deviceDiscover: function (data) {
        return Axios.default.post(`${deviceStr}/electric/getHikDeviceList`, data)
    },
    //用户配置海康空开
    configHikiDevice: function (data) {
        return Axios.default.post(`${deviceStr}/electric/saveHikDevice`, data)
    },
    //用户查询已发现海康空开
    checkHikvDevice: function (data) {
        return Axios.default.post(`${deviceStr}/electric/scan/device`, data)
    },
    /**
     * 安全管家-安防设备管理
     */
    // 分页获取安防设备列表接口
    deviceSecurityListPage: function (data) {
        return Axios.default.post(`${deviceStr}/deviceSecurity/listPage`, data)
    },
    // 新增安防设备接口
    deviceSecurityInsert: function (data) {
        return Axios.default.post(`${deviceStr}/deviceSecurity/insert`, data)
    },
    // 修改安防设备接口
    deviceSecurityUpdate: function (data) {
        return Axios.default.put(`${deviceStr}/deviceSecurity/update`, data)
    },
    // 安防设备类型接口
    deviceSecurityTypeList: function (data) {
        return Axios.default.get(`${deviceStr}/deviceType/deviceSecurity/list`)
    },
    // 电气设备类型接口
    deviceElectricTypeList: function (data) {
        return Axios.default.get(`${deviceStr}/deviceType/electric/list`)
    },
    // 删除安防设备接口
    deviceSecurityDelete: function (data) {
        return Axios.default.delete(`${deviceStr}/deviceSecurity/batchDelete`, data)
    },
    // 门磁开门
    doorOpenControl: function (data) {
        return Axios.default.post(`${deviceStr}/deviceSecurityControl/openDoor/${data}`)
    },
    // 下发信息
    deviceSecurityControlSend: function (data) {
        return Axios.default.post(`${deviceStr}/deviceSecurityControl/sendInfo`, data)
    },
    // 拉取设备终端人员指纹
    pullFingerprint: function (id) {
        return Axios.default.get(`${deviceStr}/deviceSecurityControl/pullFingerprint/${id}`)
    },
    // 根据终端id（设备id）查询终端用户列表
    endPersonList: function (data) {
        return Axios.default.get(`${deviceStr}/endPerson/listByEndId?${data}`)
    },

    /**
     * 安全管家-监控设备管理
     */
    // 获取媒体设备分页
    deviceMediaPage: function (data) {
        return Axios.default.post(`${deviceStr}/deviceMedia/page`, data)
    },
    // 新增媒体设备
    addDeviceMedia: function (data) {
        return Axios.default.post(`${deviceStr}/deviceMedia/add`, data)
    },
    // 修改媒体设备
    updateDeviceMedia: function (data) {
        return Axios.default.put(`${deviceStr}/deviceMedia/update`, data)
    },
    // 删除媒体设备
    deleteDeviceMedia: function (data) {
        return Axios.default.delete(`${deviceStr}/deviceMedia/batchDelete`, data)
    },
    // 获取设备通道列表
    deviceChannelList: function (data) {
        return Axios.default.post(`${deviceStr}/deviceChannel/list`, data)
    },
    // 修改设备通道
    updateDeviceChannel: function (data) {
        return Axios.default.put(`${deviceStr}/deviceChannel/update`, data)
    },
    // 获取设备与通道树形数据
    deviceMediaList: function (data) {
        return Axios.default.get(`${deviceStr}/deviceMedia/list?${data}`)
    },
    // 获取播放地址
    deviceMediaLive: function (data) {
        return Axios.default.post(`${deviceStr}/deviceMedia/live`, data)
    },
    // 开始云台控制
    ptzStart: function (data) {
        return Axios.default.get(`${deviceStr}/deviceChannel/ptzStart?${data}`)
    },
    // 停止云台控制
    ptzStop: function (data) {
        return Axios.default.get(`${deviceStr}/deviceChannel/ptzStop?${data}`)
    },
    // 设备及通道数据同步
    dataSync: function () {
        return Axios.default.get(`${deviceStr}/deviceMedia/dataSync`)
    },
    
    /**
     * 安全管家-环境传感器设备
     */
    // 获取环境传感器设备分页
    deviceSensePage: function (data) {
        return Axios.default.post(`${deviceStr}/deviceSense/page`, data)
    },
    // 新增环境传感器设备
    addDeviceSense: function (data) {
        return Axios.default.post(`${deviceStr}/deviceSense/add`, data)
    },
    // 修改环境传感器设备
    updateDeviceSense: function (data) {
        return Axios.default.put(`${deviceStr}/deviceSense/update`, data)
    },
    // 删除环境传感器设备
    deleteDeviceSense: function (data) {
        return Axios.default.delete(`${deviceStr}/deviceSense/batchDelete`, data)
    },
    // 获取没绑定的环境传感器设备
    deviceSenseUnbound: function (data) {
        return Axios.default.get(`${deviceStr}/deviceSense/unbound?${data}`)
    },
    // 获取继电器mqtt配置
    getMqttConfig: function (data) {
        return Axios.default.get(`${deviceStr}/deviceRelay/mqtt/get?${data}`)
    },
    // 新增IO采集开关
    addDeviceRelay: function (data) {
        return Axios.default.post(`${deviceStr}/deviceRelay/add`, data)
    },
    // 修改IO采集开关
    updateDeviceRelay: function (data) {
        return Axios.default.put(`${deviceStr}/deviceRelay/update`, data)
    },
    // 修改串口服务器开关
    updateSerialDeviceRelay: function (data) {
        return Axios.default.put(`${deviceStr}/deviceRelay/serialserver/update`, data)
    },
    // 修改IO采集mqtt配置
    updateDeviceMqtt: function (data) {
        return Axios.default.put(`${deviceStr}/deviceRelay/mqtt/update`, data)
    },
    // 获取IO采集开关信息
    getDeviceRelay: function (data) {
        return Axios.default.get(`${deviceStr}/deviceRelay/get?${data}`)
    },
    // 查看IO采集关联
    getRelation: function (data) {
        return Axios.default.get(`${deviceStr}/deviceRelay/getRelation?${data}`)
    },
    // 更新IO采集关联
    updateRelation: function (data) {
        return Axios.default.post(`${deviceStr}/deviceRelay/updateRelation`, data)
    },
    /***
     * 安全管家-空调设备管理
     */
    // 分页查看空调设备列表
    deviceAirPage: function (data) {
        return Axios.default.post(`${deviceStr}/deviceAir/page`, data)
    },
    // 新增空调设备
    addDeviceAir: function (data) {
        return Axios.default.post(`${deviceStr}/deviceAir/add`, data)
    },
    // 修改空调设备
    updateDeviceAir: function (data) {
        return Axios.default.post(`${deviceStr}/deviceAir/modify`, data)
    },
    // 删除空调设备
    deleteDeviceAir: function (data) {
        return Axios.default.delete(`${deviceStr}/deviceAir/delete`, data)
    },
    /***
     * 安全管家-UPS设备管理
     */
    // 分页查看UPS设备列表
    deviceUpsPage: function (data) {
        return Axios.default.post(`${deviceStr}/deviceUps/page`, data)
    },
    // 新增UPS设备
    addDeviceUps: function (data) {
        return Axios.default.post(`${deviceStr}/deviceUps/add`, data)
    },
    // 修改UPS设备
    updateDeviceUps: function (data) {
        return Axios.default.post(`${deviceStr}/deviceUps/modify`, data)
    },
    // 删除UPS设备
    deleteDeviceUps: function (data) {
        return Axios.default.delete(`${deviceStr}/deviceUps/delete`, data)
    },
    /***
     * 安全管家-告警管理
     */
    // 设备告警日志
    deviceAlarmList: function (data) {
        return Axios.default.post(`${deviceStr}/alarm/list`, data)
    },
    // 设备告警详情
    deviceAlarmDetail: function (data) {
        return Axios.default.get(`${deviceStr}/alarm/detail/${data}`)
    },
    // 告警状态修改
    alarmHandle: function (data) {
        return Axios.default.post(`${deviceStr}/alarm/handle`, data)
    },
    // 告警导出
    alarmExport: function (data) {
        return Axios.default.postFile(`${deviceStr}/alarm/export`, data)
    },
    // 历史告警日志
    historyAlarmList: function (data) {
        return Axios.default.post(`${deviceStr}/alarm/history/list`, data)
    },
    // 历史告警详情
    alarmHistoryDetail: function (data) {
        return Axios.default.get(`${deviceStr}/alarm/history/detail/${data}`)
    },
    // 告警历史导出
    historyAlarmExport: function (data) {
        return Axios.default.postFile(`${deviceStr}/alarm/history/export`, data)
    },
    // 根据场所id分页查询行为告警列表
    alarmBehaviorListPage: function (data) {
        return Axios.default.post(`${deviceStr}/alarmBehavior/listPage`, data)
    },
    // 清除行为告警
    deleteAlarmBehavior: function (data) {
        return Axios.default.delete(`${deviceStr}/alarmBehavior/batchDelete`, data)
    },
    // 清除行为告警
    alarmDataDetail: function (data) {
        return Axios.default.get(`${deviceStr}/alarm/data/detail?${data}`)
    },


    /**
     * 安全管家-边缘计算盒子
     */

    // 分页查看边缘计算盒子列表
    deviceBoxPage: function (data) {
        return Axios.default.post(`${deviceStr}/box/page`, data)
    },
    // 新增编辑边缘计算盒子
    addDeviceBox: function (data) {
        return Axios.default.post(`${deviceStr}/box/edit`, data)
    },
    // 删除边缘计算盒子
    deleteDeviceBox: function (data) {
        return Axios.default.post(`${deviceStr}/box/delete`, data)
    },
    // 查看边缘计算盒子详情
    deviceBoxDetail: function (data) {
        return Axios.default.get(`${deviceStr}/box/get?${data}`)
    },
    //分页边缘计算告警记录
    deviceBoxAlarmPage: function (data) {
        return Axios.default.post(`${deviceStr}/box/pageAlarm`, data)
    },
    //边缘计算告警记录详情
    deviceBoxAlarmDetail: function (data) {
        return Axios.default.get(`${deviceStr}/box/getAlarm/${data}`)
    },
    //删除边缘计算告警记录
    deleteDeviceBoxAlarm: function (data) {
        return Axios.default.post(`${deviceStr}/box/deleteAlarm`, data)
    },

    /***
     * 基础服务-考勤管理
     */
    // 获取考勤星期配置数据
    attendanceWeekConf: function (data) {
        return Axios.default.get(`${globalStr}/attendanceConf/weekConf/list?${data}`)
    },
    // 更新考勤星期配置数据
    attendanceWeekConfUpdata: function (data) {
        return Axios.default.put(`${globalStr}/attendanceConf/weekConf/update`, data)
    },
    // 根据考勤年月分页查询人员考勤数据
    attendanceListPage: function (data) {
        return Axios.default.post(`${globalStr}/attendance/listPage`, data)
    },
    // 根据考勤年月导出人员考勤数据
    attendanceExport: function (data) {
        return Axios.default.postFile(`${globalStr}/attendance/excel/export`, data)
    },
    /***
     * 安全管理-数据统计
     */
    // 场所能耗统计
    statisticsEnergy: function (data) {
        return Axios.default.get(`${deviceStr}/stats/energy/statistics?${data}`)
    },
    // 场所设备状态统计
    statisticsStatus: function (data) {
        return Axios.default.get(`${deviceStr}/stats/device/statistics?${data}`)
    },
    // 场所设备告警统计
    statisticsAlarm: function (data) {
        return Axios.default.get(`${deviceStr}/stats/home/alarm?${data}`)
    },
    // 场所设备能耗排名
    statisticsRank: function (data) {
        return Axios.default.get(`${deviceStr}/stats/energy/statistics/rank?${data}`)
    },
    // 耗电量环比
    statisticsRatio: function (data) {
        return Axios.default.get(`${deviceStr}/stats/energy/statistics/home/rank/month?${data}`)
    },
    // 场所能耗排名统计
    statisticsHomeRank: function (data) {
        return Axios.default.get(`${deviceStr}/stats/energy/statistics/home/rank?${data}`)
    },
    // 温湿度统计
    statisticTempH: function (data) {
        return Axios.default.get(`${deviceStr}/stats/sense/history-data?${data}`)
    },
    // 电气设备-今日负载统计
    statisticOverload: function (data) {
        return Axios.default.get(`${deviceStr}/stats/overload?${data}`)
    },
    // 电气设备-线路统计图（指标按小时统计信息）
    statisticLineHour: function (data) {
        return Axios.default.get(`${deviceStr}/stats/hour?${data}`)
    },
    // 查询区域路线树
    electricGetLineList: function (data) {
        return Axios.default.get(`${deviceStr}/electric-box/getLineList?${data}`)
    },
    // 路线指标列表
    electricTargetList: function (data) {
        return Axios.default.get(`${deviceStr}/electric-box/target/list?${data}`)
    },
    // 数据流向配置
    deviceNetConfig: function (data) {
        return Axios.default.post(`${deviceStr}/device/net/config`, data)
    },
    /***
     * 首页-接口
     */
    // 用户设备告警统计
    statsUserAlarm: function (data) {
        return Axios.default.get(`${deviceStr}/stats/user/alarm?${data}`)
    },
    // 获取MainPage
    getMainPage: function (data) {
        return Axios.default.get(`${placeStr}/main/getMainPage?${data}`)
    },
    // 首页-告警列表
    statsAlarmList: function (data) {
        return Axios.default.get(`${deviceStr}/stats/alarm/list?${data}`)
    },
    // 统计查询人员考勤数据
    attendanceStatistics: function (data) {
        return Axios.default.post(`${globalStr}/attendance/statistics`, data)
    },
    // 根据区域id统计查询行为告警
    behaviorStatistics: function (data) {
        return Axios.default.post(`${deviceStr}/alarmBehavior/statistics`, data)
    },
    /***
     * 电气设备-告警规则
     */
    // 查询告警规则列表
    alarmRuleList: function (devId) {
        return Axios.default.get(`${deviceStr}/alarm/rule/list/${devId}`)
    },
    // 创建告警规则
    addAlarmRule: function (data) {
        return Axios.default.post(`${deviceStr}/alarm/rule/create`, data)
    },
    // 修改告警规则
    updateAlarmRule: function (data) {
        return Axios.default.post(`${deviceStr}/alarm/rule/update`, data)
    },
    // 删除告警规则
    deleteAlarmRule: function (data) {
        return Axios.default.delete(`${deviceStr}/alarm/rule/delete?${data}`)
    },
    // 查询设备指标
    targetList: function (devId, deviceType) {
        return Axios.default.get(`${deviceStr}/target/list/${deviceType}/${devId}`)
    },
    //获取四串口服务器配置
    getFourSerialConfig: function (id) {
        return Axios.default.get(`${deviceStr}/deviceSense/fourSerialServer/${id}`)
    },
    /***
     * 安全管理-电气线路
     */
    // 获取完整的电路图数据
    getElectricLine: function (data) {
        return Axios.default.get(`${deviceStr}/electric-box/getElectricLine?${data}`)
    },
    // 获取完整的电路图数据
    getElectricDetail: function (deviceId) {
        return Axios.default.get(`${deviceStr}/electric-box/data/${deviceId}`)
    },
    /***
     * 安全管理-用电报告
     */
    // 分页查询报告列表
    reportListPage: function (data) {
        return Axios.default.get(`${deviceStr}/report/page/list?${data}`)
    },
    // 生成报告
    reportOverview: function (data) {
        return Axios.default.post(`${deviceStr}/report/overview`, data)
    },
    // 提交报告诊断意见
    reportUpdate: function (data) {
        return Axios.default.post(`${deviceStr}/report/update`, data)
    },
    // 批量删除报告
    deleteReport: function (data) {
        return Axios.default.delete(`${deviceStr}/report/deleteReport`, data)
    },
    /***
     * 安全管理-场景配置
     */
    // 分页查询场景列表
    sceneListPage: function (data) {
        return Axios.default.post(`${sceneStr}/pageList`, data)
    },
    // 添加场景
    addScene: function (data) {
        return Axios.default.post(`${sceneStr}/addScene`, data)
    },
    // 修改场景
    updateScene: function (data) {
        return Axios.default.put(`${sceneStr}/updateScene`, data)
    },
    // 删除场景
    deleteScene: function (data) {
        return Axios.default.delete(`${sceneStr}/deleteScene`, data)
    },
    // 获取场景详情
    getSceneDetail: function (id) {
        return Axios.default.get(`${sceneStr}/getSceneDetail/${id}`)
    },
    // 手动触发场景
    triggerScene: function (id) {
        return Axios.default.get(`${sceneStr}/triggerScene/${id}`)
    },
    /***
     * 安全管理-巡检管理
     */
    // 分页查询巡检任务列表
    inspectListPage: function (data) {
        return Axios.default.post(`${placeStr}/inspect/pageList`, data)
    },
    // 创建巡检任务列表
    addInspect: function (data) {
        return Axios.default.post(`${placeStr}/inspect/add`, data)
    },
    // 修改巡检任务列表
    updateInspect: function (data) {
        return Axios.default.post(`${placeStr}/inspect/update`, data)
    },
    // 巡检任务详情
    detailInspect: function (id) {
        return Axios.default.get(`${placeStr}/inspect/detail/${id}`)
    },
    // 删除巡检任务
    deleteInspect: function (data) {
        return Axios.default.delete(`${placeStr}/inspect/delete`, data)
    },
    // 分页查询巡检记录列表
    inspectRecordListPage: function (data) {
        return Axios.default.post(`${placeStr}/inspect/record/pageList`, data)
    },
    // 巡检记录详情
    inspectRecordDetail: function (id) {
        return Axios.default.get(`${placeStr}/inspect/record/detail/${id}`)
    },
    /***
     * 安全管理-资产管理
     */
    // 分页查询资产分类列表
    assetListPage: function (data) {
        return Axios.default.post(`${globalStr}/asset/page`, data)
    },
    // 查询资产分类详情
    assetDetail: function (id) {
        return Axios.default.get(`${globalStr}/asset/detail/${id}`)
    },
    // 创建资产分类
    addAsset: function (data) {
        return Axios.default.post(`${globalStr}/asset/add`, data)
    },
    // 修改资产分类
    updateAsset: function (data) {
        return Axios.default.post(`${globalStr}/asset/update`, data)
    },
    // 删除资产分类
    deleteAsset: function (data) {
        return Axios.default.delete(`${globalStr}/asset/delete`, data)
    },
    // 分页查询资产统计列表
    assetStatsListPage: function (data) {
        return Axios.default.post(`${globalStr}/asset/stats/page`, data)
    },
    // 新增资产统计
    addAssetStats: function (data, timeNum) {
        return Axios.default.postJson(`${globalStr}/asset/stats/add`, data, timeNum)
    },
    // 修改资产统计
    updateAssetStats: function (data, timeNum) {
        return Axios.default.postJson(`${globalStr}/asset/stats/update`, data, timeNum)
    },
    // 查询资产分类详情
    assetStatsDetail: function (id) {
        return Axios.default.get(`${globalStr}/asset/stats/detail/${id}`)
    },
    // 删除资产统计
    deleteAssetStats: function (data) {
        return Axios.default.delete(`${globalStr}/asset/stats/delete`, data)
    },
    // 查询资产大类列表
    getAssetList: function (data) {
        return Axios.default.get(`${globalStr}/asset/assetList?${data}`)
    },
    // 查询资产小类列表
    getAssetSubList: function (data) {
        return Axios.default.get(`${globalStr}/asset/assetSubList?${data}`)
    },
    // 新增资产维护工单
    addAssetTicket: function (data) {
        return Axios.default.post(`${globalStr}/asset/ticket/add`, data)
    },
}